import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';


export const SectionIntegrationMeta = {
    title: "Integrály",
    shortTitle: "Integrály",
    path: Path.section_integration,
    element: (sectionNumber:string) => <SectionIntegration sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionIntegration({sectionNumber}: {sectionNumber: string}) {

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{SectionIntegrationMeta.title}</h1>

        <p>
        Tato kapitola se věnuje integrálům.
        </p>


        </div>
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

