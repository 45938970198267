
import {
    AnglesMeta,
    FunctionGraphTransformationsMeta,
    FunctionGraphTransformations2Meta,
    FunctionLineMeta,
    FunctionParityMeta,
    FunctionQuadraticMeta,
    GoniometricMeta,
    SectionDerivativesMeta,
    SectionFunctionsMeta,
    SectionIntegrationMeta,
    SubstitutionMeta,
    FunctionsNotesMeta,
    SectionMathFoundationsMeta,
    FunctionsNotesMiniMeta,
    MatricesNotesMeta,
} from "./chapters";


export interface Section {
    title: string,
    shortTitle: string,
    path: string,
    element: (sectionNumber:string) => JSX.Element,
    sectionNumber: string,
}

export interface MenuItem {
    section: Section,
    menuitems?: MenuItem[],
}

function m(section: Section, sections?: Section[]): MenuItem {
    if (sections === undefined) {
        return {
            section: section,
        };
    }
    return {
        section: section,
        menuitems: sections.map(s => m(s)),
    };
}

// ORDER MATTERS! This defines the order of items in the menu (!)
export const sections: MenuItem[] = [
    m(
        SectionMathFoundationsMeta,
        [
            FunctionsNotesMiniMeta,
            MatricesNotesMeta,
            // SubstitutionMeta,
            // FunctionsNotesMeta,
            // FunctionGraphTransformationsMeta,
            // FunctionGraphTransformations2Meta,
            // FunctionParityMeta,
            // FunctionLineMeta,
            // FunctionQuadraticMeta,
            // AnglesMeta,
            GoniometricMeta,
        ],
    ),
    // m(
    //     SectionDerivativesMeta,
    //     [

    //     ],
    // ),
    // m(
    //     SectionIntegrationMeta,
    //     [

    //     ],
    // ),
]


function setSectionNumbers(menuitems: MenuItem[], prefix="") {
    let n:number = 1;
    for (const m of menuitems) {
        m.section.sectionNumber = prefix + (prefix ? "." : "") + n.toString();
        // console.log("Setting section number", s.sectionNumber, "for", s.title);
        if (m.menuitems !== undefined) {
            setSectionNumbers(m.menuitems, m.section.sectionNumber);
        }
        n += 1;
    }
}

setSectionNumbers(sections, "");
