import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


const substitution = require('../assets/images/substitution.png');


export const SubstitutionMeta = {
    title: "Dosazení",
    shortTitle: "Dosazení",
    path: Path.substitution,
    element: (sectionNumber:string) => <Substitution sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "radians",
];

export function Substitution({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{SubstitutionMeta.title}</h1>

        <p>
        Dosazení 'něčeho' za 'něco' v nějakém vzorci je základní operace, která se v matematice provádí v mnoha typech úloh
        (tvorba a derivování složených funkcí, řešení rovnic, integrování, pravděpodobnost ...). Zde ukazujeme princip
        a 'mechanický' postup pro ty, kdo by si v tom nebyli jistí.
        </p>

        <p>
        Dejme tomu, že něco dosazujeme za proměnnou {"$x$"}. Potom si každý
        výskyt {"$x$"} nahradíme dvojicí závorek {"$\\textcolor{red}{( \\  )}$"} - tj.
        "prázdým slotem" - a pak mezi tyto závorky (do těchto "slotů") vepíšeme věc, kterou dosazujeme.
        Některé ze závorek lze potom v dalším kroku v rámci zjednodušení vzorce odstanit.
        Pozor - některé závorky odstranit nejdou (musí tam zůstat): Např.
        při dosazení {"$x = -1$"} do {"$x^2$"} získáme {"$(-1)^2 = 1$"}, a
        je <strong>hrubá chyba</strong> napsat toto jako {"$-1^2$"} (což je rovno {"$-1$"}).
        </p>

        <p>Princip je ilustrován na obrázku níže, kde za {"$x$"} dosazujeme prase:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={substitution} style={{width: "25%"}}/>
        </div>

        <p>
        Ukázka 1: Za {"$x$"} dosaďme {"$x + 1$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$x - 1$"} </td>
                    <td>{"$( \\ ) + 1$"} </td>
                    <td>{"$(x+1) - 1$"} </td>
                    <td>{"$x$"} </td>
                </tr>
                <tr>
                    <td>{"$x^2$"} </td>
                    <td>{"$( \\ )^2$"} </td>
                    <td>{"$(x+1)^2$"} </td>
                    <td></td>
                </tr>
                <tr>
                    <td>{"$e^{-\\frac{x}{2}}$"}</td>
                    <td>{"$e^{-\\frac{( \\ )}{2}}$"}</td>
                    <td>{"$e^{-\\frac{\\big(x+1\\big)}{2}}$"}</td>
                    <td>{"$e^{-\\frac{x+1}{2}}$"}</td>
                </tr>
                <tr>
                    <td>{"$x \\sin(2x)$"}</td>
                    <td>{"$( \\ ) \\sin\\big(2( \\ )\\big)$"}</td>
                    <td>{"$(x+1) \\sin\\big(2(x+1)\\big)$"}</td>
                    <td>{"$(x+1) \\sin(2x+2)$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ukázka 2: Za {"$x$"} dosaďme {"$\\frac{x}{3}$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$x - 1$"} </td>
                    <td>{"$( \\ ) + 1$"} </td>
                    <td>{"$(\\frac{x}{3}) - 1$"} </td>
                    <td>{"$\\frac{x}{3} - 1$"} </td>
                </tr>
                <tr>
                    <td>{"$x^2$"} </td>
                    <td>{"$( \\ )^2$"} </td>
                    <td>{"$(\\frac{x}{3})^2$"} </td>
                    <td>{"$\\frac{x^2}{9}$"} </td>
                </tr>
                <tr>
                    <td>{"$e^{-\\frac{x}{2}}$"} </td>
                    <td>{"$e^{-\\frac{( \\ )}{2}}$"} </td>
                    <td>{"$e^{-\\frac{\\big(\\frac{x}{3}\\big)}{2}}$"} </td>
                    <td>{"$e^{-\\frac{x}{6}}$"} </td>
                </tr>
                <tr>
                    <td>{"$x \\sin(2x)$"} </td>
                    <td>{"$( \\ ) \\sin\\big(2( \\ )\\big)$"} </td>
                    <td>{"$\\left(\\frac{x}{3}\\right) \\sin\\big(2(\\frac{x}{3})\\big)$"} </td>
                    <td>{"$\\frac{x}{3} \\sin(\\frac{2}{3}x)$"} </td>
                </tr>
            </tbody>
        </table>


        <p>
        Ukázka 3: Za {"$x$"} dosaďme {"$-x$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$x - 1$"}</td>
                    <td>{"$( \\ ) + 1$"}</td>
                    <td>{"$(-x) - 1$"}</td>
                    <td>{"$-x - 1$"}</td>
                </tr>
                <tr>
                    <td>{"$x^2$"}</td>
                    <td>{"$( \\ )^2$"}</td>
                    <td>{"$(-x)^2$"}</td>
                    <td>{"$x^2$"}</td>
                </tr>
                <tr>
                    <td>{"$x^3$"}</td>
                    <td>{"$( \\ )^3$"}</td>
                    <td>{"$(-x)^3$"}</td>
                    <td>{"$-x^3$"}</td>
                </tr>
                <tr>
                    <td>{"$e^{-\\frac{x}{2}}$"}</td>
                    <td>{"$e^{-\\frac{( \\ )}{2}}$"}</td>
                    <td>{"$e^{-\\frac{\\big(-x\\big)}{2}}$"}</td>
                    <td>{"$e^{\\frac{x}{2}}$"}</td>
                </tr>
                <tr>
                    <td>{"$x \\sin(2x)$"}</td>
                    <td>{"$( \\ ) \\sin\\big(2( \\ )\\big)$"}</td>
                    <td>{"$(-x) \\sin\\big(2(-x)\\big)$"}</td>
                    <td>{"$-x \\sin(-2x)$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ukázka 4: Za {"$x$"} dosaďme {"$x^2 - 1$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$x^2 - 2x + 1$"}</td>
                    <td>{"$( \\ )^2 - 2( \\ ) + 1$"}</td>
                    <td>{"$(x^2 - 1)^2 - 2(x^2 - 1) + 1$"}</td>
                    <td style={{fontSize: 'small'}}>{"$x^4 - 2x^2 + 1 - 2x^2 + 2 + 1 = x^4 - 4x^2 + 2$"}</td>
                </tr>
                <tr>
                    <td>{"$\\sqrt{x + 1}$"}</td>
                    <td>{"$\\sqrt{( \\ ) + 1}$"}</td>
                    <td>{"$\\sqrt{(x^2 - 1) + 1}$"}</td>
                    <td>{"$|x|$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ukázka 4: Za {"$x$"} dosaďme {"$\\cos(t)$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$1 - x^2$"}</td>
                    <td>{"$1 - ( \\ )^2$"}</td>
                    <td>{"$1 - (\\cos(t))^2$"}</td>
                    <td>{"$\\sin^2(t)$"}</td>
                </tr>
                <tr>
                    <td>{"$2x\\sin(t)$"}</td>
                    <td>{"$2( \\ )\\sin(t)$"}</td>
                    <td>{"$2\\big(\\cos(t)\\big)\\sin(t)$"}</td>
                    <td>{"$\\sin{(2t)}$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ukázka 5: Za {"$\\cos(t)$"} dosaďme {"$\\frac{1}{2}$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$\\frac{\\cos{(t)}}{1 + \\cos^2(t)}$"}</td>
                    <td>{"$\\frac{( \\ )}{1 + ( \\ )^2}$"}</td>
                    <td>{"$\\frac{\\frac{1}{2}}{1 + \\big(\\frac{1}{2}\\big)^2}$"}</td>
                    <td>{"$\\frac{2}{5}$"}</td>
                </tr>
                <tr>
                    <td>{"$\\sin^2(t)$"}</td>
                    <td>{"$\\sin^2(t) = 1 - \\cos^2(t) \\rightarrow 1 - ( \\ )^2$"}</td>
                    <td>{"$1 - \\big(\\frac{1}{2}\\big)^2$"}</td>
                    <td>{"$\\frac{3}{4}$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ukázka 6: Za {"$x$"} dosaďme {"$\\frac{1}{x}$"} v následujících vzorcích:
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Originál</th>
                    <th>Sloty</th>
                    <th>Dosazeno</th>
                    <th>Zjednodušeno</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$1$"}</td>
                    <td>{"$1 \\text{ (nejsou)}$"}</td>
                    <td>{"$1$"}</td>
                    <td>{"$1$"}</td>
                </tr>
                <tr>
                    <td>{"$t^2 - 1$"}</td>
                    <td>{"$t^2 - 1 \\text{ (nejsou)}$"}</td>
                    <td>{"$t^2 - 1$"}</td>
                    <td>{"$t^2 - 1$"}</td>
                </tr>
            </tbody>
        </table>


        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

