import React, { useEffect, useRef, useState } from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';
import Footer from '~/Footer';

// const angles = require('../assets/images/angles.png');

export const MatricesNotesMeta = {
    title: "Matrix-related concepts",
    shortTitle: "Matrices",
    path: Path.matrices_notes,
    element: (sectionNumber:string) => <MatricesNotes sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fnm-def",
    "fnm-simple-fs",
    "fnm-inverse",
];

// vysvětlit to od kruhu, napsat že bod patří kruhu K ; pak napsat (x,y) \in f

//

export function MatricesNotes({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{MatricesNotesMeta.title}</h1>

        <p>For more details, refer to any book on <strong>linear algebra</strong>.</p>

        <h2 id="fnm-def">Matrix concepts</h2>
        <p>
        <a href='https://en.wikipedia.org/wiki/Matrix_(mathematics)' target="_blank" rel="noopener noreferrer" className="external-link">Matrix</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Matrix_multiplication' target="_blank" rel="noopener noreferrer" className="external-link">Matrix multiplication</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Determinant' target="_blank" rel="noopener noreferrer" className="external-link">Determinant</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Identity_matrix' target="_blank" rel="noopener noreferrer" className="external-link">Identity matrix</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Main_diagonal' target="_blank" rel="noopener noreferrer" className="external-link">Main diagonal</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Row_and_column_vectors' target="_blank" rel="noopener noreferrer" className="external-link">Row and column vectors</a>.<br/>
        </p>
        {/* <a href='https://en.wikipedia.org/wiki/Map_(mathematics)' target="_blank" rel="noopener noreferrer" className="external-link">Mapping</a>.<br/> */}

        <p>
        <a href='https://en.wikipedia.org/wiki/Rank_(linear_algebra)' target="_blank" rel="noopener noreferrer" className="external-link">Rank</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Linear_combination' target="_blank" rel="noopener noreferrer" className="external-link">Linear combination</a>.<br/>
        <a href='https://en.wikipedia.org/wiki/Linear_independence' target="_blank" rel="noopener noreferrer" className="external-link">Linear independence</a>.<br/>
        </p>

        <p>
        For a square matric {"$A_{n \\times n}$"}, the following are equivalent:
        </p>

        <ul>
            <li>The matrix is invertible.</li>
            <li>The matrix has full rank ({"$n$"}).</li>
            <li>The columns are linearly independent.</li>
            <li>The row are linearly independent.</li>
            <li>The determinant is nonzero.</li>
            <li>The equation {"$Ax = 0$"} has only the trivial solution {"$x = 0$"}.</li>
            <li>For each vector {"$b$"}, the equation {"$Ax = b$"} has a unique solution.</li>
        </ul>


        <Footer />
        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

