import React from 'react';

import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useLocation,
} from "react-router-dom";


import './App.css';
import { ContentsMenu } from './Menu';


import { LandingPage } from './LandingPage';
import { NavBar } from './NavBar';
import { Path } from './paths';


import {
    AnglesMeta,
    FunctionGraphTransformationsMeta,
    FunctionGraphTransformations2Meta,
    FunctionLineMeta,
    FunctionParityMeta,
    FunctionQuadraticMeta,
    GoniometricMeta,
    SectionDerivativesMeta,
    SectionFunctionsMeta,
    SectionIntegrationMeta,
    SubstitutionMeta,
    FunctionsNotesMeta,
    SectionMathFoundationsMeta,
    FunctionsNotesMiniMeta,
    MatricesNotesMeta,
} from './chapters';

import { Section, sections } from './defs';

// vzorec je jen zápisem mentálního obrazu toho, co děláme
// nedosazujeme bezduše do vzorce
//  manipulace vzorce je technická /bez představy/, i když i to lze na
// určitých mezivýsledcích a určitě na finálním výsledku

//
// PAMATUJ SI, ŽE NEPises ucenbinci,
// ale manual k typovym prikladum ktere pokryva cviko
//

const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes('histogram-app');

    return (
        <div className="App" style={{display: "flex", gap: 0, width: "100vw"}}>
            <div className="sidebar" style={{display: "flex", flexDirection: "column"}}>
                <NavBar/>
                <div className='menu-container' style={{flexGrow: 1}}>
                    <ContentsMenu sections={sections}/>
                </div>
                <div className='small-text'>version Sat 14.9.2024 21:00</div>
            </div>
            <div className="page" style={{flexBasis: isHistogramApp ? '0' : '6', flexGrow: 6}}>
                <Outlet />
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
    }

}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout/>,
        children: [
            {
                path: '',
                element: (
                    <LandingPage/>
                )
            },
            // {
            //     path: 'sources',
            //     element: (
            //         <Sources/>
            //     )
            // },
            routerChild(FunctionsNotesMiniMeta),
            routerChild(MatricesNotesMeta),
            // routerChild(FunctionGraphTransformations2Meta),
            // routerChild(FunctionGraphTransformationsMeta),
            // routerChild(FunctionLineMeta),
            // routerChild(FunctionParityMeta),
            // routerChild(FunctionQuadraticMeta),
            // routerChild(FunctionsNotesMeta),
            routerChild(GoniometricMeta),
            // routerChild(SectionDerivativesMeta),
            // routerChild(SectionFunctionsMeta),
            routerChild(SectionMathFoundationsMeta),
            // routerChild(SubstitutionMeta),
        ],
    }
]);


export default function App() {
    return (
        <RouterProvider router={router} />
    );
}


