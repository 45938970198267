

export enum Path {
    root = "/",

    section_math_foundations = "section-math-foundations",

    section_functions = "section-functions",
    section_derivatives = "section-derivatives",
    section_integration = "section-integration",

    goniometric = "goniometric",
    angles = "angles",
    function_parity = "function-parity",
    function_line = "function-line",
    function_quadratic = "function-quadratic",
    substitution = "substitution",
    function_graph_transformations = "function-graph-transformations",
    function_graph_transformations2 = "function-graph-transformations2",

    functions_notes = "functions-notes",
    functions_notes_mini = "functions-notes-mini",
    matrices_notes = "matrices-notes",
}