import React from 'react';

import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import Footer from '~/Footer';


export const SectionMathFoundationsMeta = {
    title: "Foundations",
    shortTitle: "Foundations",
    path: Path.section_math_foundations,
    element: (sectionNumber:string) => <SectionMathFoundations sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionMathFoundations({sectionNumber}: {sectionNumber: string}) {

    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card">

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{SectionMathFoundationsMeta.title}</h1>

        <p>
        This chapter provides references for some of the foundational concepts in mathematics.
        </p>


        </div>
        <Footer />
        </div>
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

