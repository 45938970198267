import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Home, Users, BookOpen, BarChart2, Shuffle,  } from 'react-feather';

import { useNavigate } from 'react-router-dom';


export function NavBar()  {
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate('');
    };

    // const navigateToSources = () => {
    //     navigate('/sources');
    // };


    return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: 10}}>
            <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-home" data-tooltip-content="Home" onClick={navigateHome}>
                    <Home size={20}/>
                </button>
                <Tooltip id="tooltip-home"/>
            </div>
            {/* <div>
                <button className="navigation-icon" data-tooltip-id="tooltip-sources" data-tooltip-content="Zdroje a literatura" onClick={navigateToSources}>
                    <BookOpen size={20}/>
                </button>
                <Tooltip id="tooltip-sources"/>
            </div> */}
        </div>
    );
}
