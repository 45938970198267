import React, { useEffect, useRef, useState } from 'react';


import { MathJaxWrapper } from "../MathJaxWrapper";
import { MathJax } from 'better-react-mathjax';
import { Path } from '~/paths';
import ImageCarousel from '~/ImageCarousel';
import { TOC, TOCProps, defineTOCElements } from '~/TOC';


const gt_axplusb = require('../assets/images/gt_axplusb.png');
const gt_axplusb31 = require('../assets/images/gt_axplusb31.png');
const gt_axplusb31_b = require('../assets/images/gt_axplusb31-b.png');

const gt_axplusb_t = require('../assets/images/gt_axplusb-t.png');
const gt_axplusb_x2_simple = require('../assets/images/gt_axplusb_x2_simple.png');
const gt_axplusb_x2_twoway = require('../assets/images/gt_axplusb_x2_twoway.png');
const gt_axplusb_x2_fixedpoint = require('../assets/images/gt_axplusb_x2_fixedpoint.png');

const gtv1 = require('../assets/images/gtv1.png');
const gtv2 = require('../assets/images/gtv2.png');
const gtv3 = require('../assets/images/gtv3.png');
const gtv4 = require('../assets/images/gtv4.png');
const gtv5 = require('../assets/images/gtv5.png');
const gtv6 = require('../assets/images/gtv6.png');
const gtv7 = require('../assets/images/gtv7.png');
const gtv8 = require('../assets/images/gtv8.png');
const gtv9 = require('../assets/images/gtv9.png');



export const FunctionGraphTransformations2Meta = {
    title: "Grafy: transformace (ax+b)",
    shortTitle: "Grafy: transformace (ax+b)",
    path: Path.function_graph_transformations2,
    element: (sectionNumber:string) => <FunctionGraphTransformations2 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fgt2-summary",
    "fgt2-construction-g",
    "fgt2-graph-transform",
    "fgt2-simple-cases",
    "fgt2-order-of-op",
    "fgt2-fixedpoint",
];


const images = [gtv1, gtv2, gtv3, gtv4, gtv5, gtv6, gtv7, gtv8, gtv9];


export function FunctionGraphTransformations2({sectionNumber}: {sectionNumber: string}) {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec, setTOCItems);
    }, []);


    return (
        <MathJaxWrapper>
        <MathJax>
        <div className="chapter-container">
        <div className="centered-content">
        <div className="card" ref={chapterRef}>

        <h1><span style={{paddingRight: 10}}>{sectionNumber}</span>{FunctionGraphTransformations2Meta.title}</h1>

        <h2 id="fgt2-summary">Shrnutí</h2>

        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Transformace vzorce</th>
                    <th>Transformace grafu</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>{"$f(x) \\rightarrow af(x) + b$"}</td><td>vertikální škálování faktorem {"$a$"}, pak vertikální posun o {"$b$"}</td></tr>
                <tr><td>{"$f(x) \\rightarrow f(ax + b)$"}</td><td>horizontální škálování faktorem {"$\\textcolor{green}{\\frac{1}{a}}$"}, pak horizontální posun o {"$\\textcolor{green}{-\\frac{b}{a}}$"}</td></tr>
            </tbody>
        </table>

        <p>
        Transformace {"$f(ax + b)$"} může působit "neintuitivně" - zde si ukážeme, proč funguje jak funguje.
        Obrázek níže situaci ilustruje na případě {"$g(x) = f(3x + 1)$"}, tedy {"$a = 3$"}, {"$b = 1$"}:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb} style={{width: "90%"}}/>
        </div>

        <h2 id="fgt2-construction-g">Konstrukce {"$g(x) = f(ax + b)$"}</h2>

        <p>
        Situace je následující:
        </p>

        <ul>
            <li><p>
            Máme funkci {"$f$"}, známe její graf. Z této funkce "vycházíme".
            </p></li>
            <li><p>
            Definujeme <strong>novou</strong> funkci {"$g$"} pomocí funkce {"$f$"} jako: {"$g(x) = f(ax + b)$"}.
            </p></li>
        </ul>

        <p>Slovně popsáno: Vybereme si konkrétní {"$x$"}, a chceme vědět, jakou hodnotu {"$g(x)$"} zde má mít funkce {"$g$"}:
        číslo {"$x$"} převedeme na {"$ax + b$"}, a podíváme se na hodnotu funkce {"$f$"} v tomto bodě: {"$f(ax + b)$"}.
        Tuto hodnotu pak použijeme jako hodnotu funkce {"$g$"} v bodě {"$x$"}: {"$g(x) := f(ax + b)$"}.
        Postup je ilustrován na sérii obrázků níže:
        </p>

        <ImageCarousel images={images}/>

        <h2 id="fgt2-graph-transform">Transformace grafu {"$f \\rightarrow g$"}</h2>

        <p>
        Konstrukce funkce {"$g$"} odpovídá cestě {"$x$"} <span style={{color: 'red'}}>po červených šipkách</span>.
        </p>

        <p>Když se ale budeme ptát, jakou transformaci (kompresi, roztažení, posunutí) máme aplikovat na
        graf funkce {"$f$"} (spodní modrou křivku), abychom tím získali graf funkce {"$g$"},
        to odpovídá cestě opačné - po <span style={{color: 'green'}}>zelených šipkách</span>:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb31} style={{width: "80%"}}/>
        </div>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb31_b} style={{width: "80%"}}/>
        </div>

        <p>Vlastně se ptáme: Na které souřadnici {"$x$"} bude použita hodnota {"$f(t)$"} z daného {"$t$"}?
        Protože {"$t = ax + b$"}, pak je to na souřadnici
        {`$$
            x = \\frac{t - b}{a} = \\frac{t}{a} - \\frac{b}{a}.
        $$`}.
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb_t} style={{width: "80%"}}/>
        </div>

        <p>
        Souřadnice {"$t$"} v původním grafu se přesune na souřadnici {"$x = \\frac{t}{a} - \\frac{b}{a}$"} v novém grafu
        - to odpovídá škálování osy {"$x$"} faktorem {"$\\frac{1}{a}$"} a posunutí o {"$-\\frac{b}{a}$"}. Je to inverzní transformace.
        </p>

        <h2 id="fgt2-simple-cases">Jednodušší případy</h2>

        <p>
        Čistý posun když {"$a = 1$"}:
        {`$$
            f(ax + b) = f(x + b), \\quad   x = \\frac{t}{1} - \\frac{b}{1} = t - b.
        $$`}
        Čisté roztažení/komprese když {"$b = 0$"}:
        {`$$
            f(ax + b) = f(ax), \\quad   x = \\frac{t}{a} - \\frac{0}{a} = \\frac{t}{a}.
        $$`}
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb_x2_simple} style={{width: "70%"}}/>
        </div>

        <h2 id="fgt2-order-of-op">Pořadí operací</h2>

        <p>
        [A] Pokud chceme graf napřed škálovat, a potom posouvat, potom máme {"$x = \\frac{1}{a}t - \\frac{b}{a}$"}:<br/>
        </p>
        <div style={{textAlign: 'center'}}>
        škálování faktorem {"$\\frac{1}{a}$"} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp; posunutí o {"$-\\frac{b}{a}$"}.<br/>
        </div>
        <p>
        V prvním kroku se bod 0 nepohne, v druhém se posune na pozici {"$-\\frac{b}{a}$"}.
        </p>
        <p><i>V obrázcích nahoře tento postup odpovídá průchodu podél zelených šipek.</i></p>

        <p>
        [B] Pokud chceme graf nejprve posunout, a potom škálovat, potom máme {"$x = \\frac{1}{a}(t - b)$"}:<br/>
        </p>
        <div style={{textAlign: 'center'}}>
        posunutí o {"$-b$"} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp; škálování faktorem {"$\\frac{1}{a}$"}.<br/>
        </div>
        <p>
        Škálování má vždy pevný bod v nule (nula zůstává na nule), a všechny ostatní hodnoty se hýbou.
        V prvním kroku se bod 0 posune na bod {"$-b$"}, a v druhém se také pohne: přesune se na pozici {"$-\\frac{b}{a}$"}.
        </p>
        <p><i>V obrázcích nahoře tento postup odpovídá zpětnému průchodu podél červených šipek.</i></p>

        <p>Oba případy jsou ilustrovány zde, pro {"$g(x) = f(2x+1)$"}:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb_x2_twoway} style={{width: "85%"}}/>
        </div>

        <h2 id="fgt2-fixedpoint">Pevný bod</h2>
        <p>
        Škálování má vždy pevný bod v nule: bod 0 zůstává na nule, a všechny ostatní body se hýbou.
        Pokud složíme škálování a posun, výsledná transformace se dá považovat považovat za škálování se stejným faktorem,
        ale s jiným pevným bodem než nula.
        </p>
        <p>
        Pevný bod je takový bod {"$x^*$"}, který se při transformaci nepohne, resp. se transformuje sám na sebe:
        {`$$
            ax^* + b = x^* \\quad \\Rightarrow \\quad x^* = -\\frac{b}{a-1}.
        $$`}
        Toto funguje pro všechny hodnoty {"$a$"} kromě {"$a = 1$"}.
        Je-li {"$a = 1$"}, pak se jedná o čistý posun, a pevný bod tedy nemůže existovat.
        </p>
        <p>
        Například pro {"$g(x) = f(2x+1)$"} máme pevný bod v {"$x^* = -\\frac{1}{2-1} = - 1$"}.
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={gt_axplusb_x2_fixedpoint} style={{width: "22%"}}/>
        </div>

        <h2 id="fgt2-inversenote">Poznámka k inverzní interpretaci</h2>

        <p>
        Kdybychom měli zadáno: Funkce {"$g(x)$"} má vozec {"$g(x) = x^2$"} a získali jsme ji jako transformaci funkce {"$f(x)$"} tak, že platí {"$g(x) = f(2x+1)$"}.
        Jaký je graf funkce {"$f(x)$"}?
        <p>

        </p>
        Potom víme, že když graf funkce {"$g(x)$"} roztáhneme 2 krát (s pevným bodem v 0)
        a posuneme o 1 doprava, dostaneme graf funkce {"$f(x)$"}.
        </p>

        <p>
        Vzorec: {"$f(2x + 1) = x^2 \\quad \\rightarrow \\quad f(t) = \\big(\\frac{1}{2}(t - 1)\\big)^2$"}.
        </p>

        </div>
        </div>
        <TOC headers={TOCItems} />
        </div>
        </MathJax>
        </MathJaxWrapper>
    );
}

